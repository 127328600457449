<template>
  <div id="Business">
    <div class="baner">
           <el-carousel
        :interval="4000"
        style="width: 100%"
        arrow="always"
        height="780px"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            :class="className"
            fit="cover"
            style="width: 100%; height: 576px"
            :src="
              bannerdd.length !== 0
                ? 'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.image
                : item.image
            "
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">合作企业</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="bix-li-box flex JCcenter">
          <div class="bix-li-box-li">
            <div class="bix-li-box-li-bg1"></div>
            <div class="bix-li-box-li-text tac fs18 hui333">万马物业</div>
          </div>
          <div class="bix-li-box-li">
            <div class="bix-li-box-li-bg2"></div>
            <div class="bix-li-box-li-text tac fs18 hui333">城南物业</div>
          </div>
          <div class="bix-li-box-li">
            <div class="bix-li-box-li-bg3"></div>
            <div class="bix-li-box-li-text tac fs18 hui333">齐家物业</div>
          </div>
          <div class="bix-li-box-li">
            <div class="bix-li-box-li-bg4"></div>
            <div class="bix-li-box-li-text tac fs18 hui333">友顺物业</div>
          </div>
        </div>
      </div>
      <div class="bix-li" style="margin-bottom: 200px">
        <div class="tac fs36 fw6" style="margin-top: 112px">联系我们</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="flex bix-li-box">
          <div class="bix-li-box-img"></div>
          <div>
            <div class="bix-li-box-title hui333 fw5 fs24">
              杭州聚达物联科技有限公司
            </div>
            <div class="bix-li-box-title2 fs20 hui333 fw5">公司地址：</div>
            <div class="bix-li-box-title3 fs18 hui666">
              地址：杭州市上城区中豪五星国际4幢9楼
            </div>
            <div class="bix-li-box-title2 fs20 hui333 fw5">联系方式：</div>
            <div class="bix-li-box-title3 fs18 hui666">
              咨询热线：0571-56308881
            </div>
            <div class="bix-li-box-title3 fs18 hui666">
              邮箱：234829@126.com
            </div>
            <div class="bix-li-box-title3 fs18 hui666">网址：wwww.ljxd.com</div>
          </div>
        </div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import { getBanner } from "@/api/new";
import Fuwu from "@/components/Fuwu.vue";
export default {
  name: "Business",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      bannerList: [{ id: 1, image: require("@/assets/business1.png") }],
      bannerdd: [],
    };
  },
  mounted() {
    this.getbanner();
  },
  methods: {
    getbanner() {
      getBanner({ type: 6 }).then((res) => {
        console.log(res.data, "banner");
        this.bannerdd = res.data;
        if (res.data.length !== 0) {
          this.bannerList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
#Business {
  .baner {
    width: 1920px;
    height: 576px;
    // background-image: url(~@/assets/business1.png);
    // background-size: 100% 100%;
  }
  .box {
    width: 1200px;
    margin: 0 360px;
    .bix-li {
      width: 1200px;
      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }
      .bix-li-box {
        width: 1200px;
        margin-top: 112px;
        .bix-li-box-li {
          width: 280px;
          height: 362px;
          margin-right: 26px;
          .bix-li-box-li-bg1 {
            width: 280px;
            height: 280px;
            background-image: url(~@/assets/business2.png);
            background-size: 100% 100%;
          }
          .bix-li-box-li-bg2 {
            width: 280px;
            height: 280px;
            background-image: url(~@/assets/business3.png);
            background-size: 100% 100%;
          }
          .bix-li-box-li-bg3 {
            width: 280px;
            height: 280px;
            background-image: url(~@/assets/business4.png);
            background-size: 100% 100%;
          }
          .bix-li-box-li-bg4 {
            width: 280px;
            height: 280px;
            background-image: url(~@/assets/business5.png);
            background-size: 100% 100%;
          }
          .bix-li-box-li-text {
            width: 280px;
            height: 82px;
            line-height: 82px;
          }
        }
        .bix-li-box-li:last-child {
          margin-right: 0;
        }
        .bix-li-box-img {
          width: 783px;
          height: 558px;
          background-image: url(~@/assets/Contact2.png);
          background-size: 100% 100%;
          margin-right: 40px;
        }
        .bix-li-box-title {
          margin-top: 28px;
        }
        .bix-li-box-title2 {
          margin-top: 60px;
        }
        .bix-li-box-title3 {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>